import React from "react";

import HashTagAdd from "../../components/HashTagAdd";
import WordScramble from "../../components/WordScramble";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const DigitalImages = () => {
  return (
    <Layout>
      <Head title="Digital Tools" />

      <WordScramble />
      <hr />
      <HashTagAdd />
    </Layout>
  );
};

export default DigitalImages;
