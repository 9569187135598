import React, { useState } from "react"
import "./tools.css"

function shuffle(array) {
  let counter = array.length

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    let index = Math.floor(Math.random() * counter)

    // Decrease counter by 1
    counter--

    // And swap the last element with it
    let temp = array[counter]
    array[counter] = array[index]
    array[index] = temp
  }
  return array
} //shuffle

const WordScramble = () => {
  const [scrambleText, setScrambleText] = useState("")

  function handleScrambleClear() {
    setScrambleText("")
    document.querySelector("#scrambleSource").value = ""
  }

  function runScramble() {
    var inputText = document.querySelector("#scrambleSource").value
    var arrayedText = inputText.split(" ")
    var scrambledText = shuffle(arrayedText)
    var scrambledTextReturn = scrambledText.join(" ")
    setScrambleText(scrambledTextReturn)
  }

  return (
    <div>
      <div className="App">
        <h1>Word Scramble</h1>
        <p>Enter text to scramble sentence / paragraph</p>
        <input type="text" id="scrambleSource" />
        <br />
        <button onClick={runScramble} style={{ marginRight: 10 }}>
          scramble text
        </button>
        <button onClick={handleScrambleClear}>clear</button>
        <p id="scrambleResults" style={{ marginBottom: 0 }}>
          {scrambleText}
        </p>
      </div>
    </div>
  )
}

export default WordScramble
