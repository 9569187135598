import React, { useState } from "react"
import "./tools.css"

function HashTagAdd() {
  const [word, setWord] = useState("")

  function handleClear() {
    document.querySelector("#inputBox").value = ""
    setWord("")
  }

  function handleClick() {
    var inputString
    inputString = document.querySelector("#inputBox").value
    var splitString = inputString.split(" ")
    var resultString = ""
    for (var i = 0; i < splitString.length; i++) {
      resultString += "#" + splitString[i] + " "
    }
    setWord(resultString)
    console.log("it worked")
  }

  return (
    <div className="App">
      <h1>Add Hashtag(s)</h1>
      <p>Enter words separated by spaces to add a hashtag to each word</p>
      <input type="text" id="inputBox" />
      <br />
      <button onClick={handleClick} style={{ marginRight: 10 }}>
        add hashtag(s)
      </button>
      <button onClick={handleClear}>clear</button>
      <p id="results">{word}</p>
    </div>
  )
}

export default HashTagAdd
